import { getToken } from '@/api/obs.js'
import ObsClient from '@/assets/js/obs.js'

const bucket = 'bcmarket'

export default function upload (file, progressCallback) {
  console.log(file, 'upload')
  return new Promise((resolve, reject) => {
    // 获取token
    getToken().then(res => {
      const obsClient = new ObsClient({
        access_key_id: res.access,
        secret_access_key: res.secret,
        security_token: res.securitytoken,
        server: 'https://obs.cn-south-1.myhuaweicloud.com',
        timeout: 60 * 5
      })

      const now = new Date()
      const format = (value) => value < 10 ? '0' + value : value
      const folder = '' + now.getFullYear() + format(now.getMonth() + 1) + format(now.getDate()) + '/'
      const uploadKey = folder + +new Date() + '' + parseInt(Math.random() * (999999 - 100000 + 1) + 100000, 10)
      if (file.size < 100 * 1024 * 1024) { // 小于100MB
        obsClient.putObject(
          {
            Bucket: bucket,
            Key: uploadKey,
            SourceFile: file,
            // 上传进度回调
            ProgressCallback: progressCallback || (() => {})
          },
          (err, result) => {
            if (err) {
              console.error(err, result)
              reject(err)
            } else {
              // result.CommonMsg.Status === 200
              resolve(`https://${bucket}.obs.cn-south-1.myhuaweicloud.com/${uploadKey}`)
            }
          }
        )
      } else {
        /**
                               * 断点续传文档地址
                               * https://support.huaweicloud.com/sdk-browserjs-devg-obs/obs_24_0511.html
                               */
        obsClient.uploadFile({
          Bucket: bucket,
          Key: uploadKey,
          SourceFile: file,
          PartSize: 5242880, // 分片大小 5m
          TaskNum: 1, // 分段上传时的最大并发数，默认为1
          // 上传进度回调
          ProgressCallback: progressCallback
          // 获取断点续传控制参数的回调函数
          //   ResumeCallback: this.resumeCallback
        }, (err, result) => {
          if (err) {
            console.log(err)
            reject(err)
          } else {
            resolve(`https://${bucket}.obs.cn-south-1.myhuaweicloud.com/${uploadKey}`)
          }
        })
      }
    }).catch(err => {
      reject(err)
    })
  })
}
